import { Box, Center, Flex, Heading, Image } from "@chakra-ui/react"
import { ClerkLoaded, ClerkLoading, SignIn } from "@clerk/clerk-react"
import { Card } from "~/components"
import { CardBox } from "~/components/CardBox"
import { LoadingLogo } from "~/components/Logo/loading"

const LoginPage = (): JSX.Element => {
  return (
    <Flex h="100dvh" p={4}>
      <Center
        pos="relative"
        borderWidth={1}
        rounded="2xl"
        flex={1}
        borderColor="gray.200"
        backgroundColor="gray.50"
        backgroundImage="url(/grid-bg.svg?9)"
        backgroundPosition="bottom center"
        backgroundRepeat="repeat"
        backgroundSize="40px auto"
        zIndex={1}
        _after={{
          content: '""',
          pos: "absolute",
          inset: 0,
          bgGradient: "linear(to-b, rgba(255, 255, 255, 0), white)",
          zIndex: -1,
          borderBottomRadius: "2xl",
        }}
      >
        <Box minW={400}>
          <CardBox shadow="0 6px 12px rgba(0, 0, 0, 0.05)">
            <Card p={8} shadow="none !important">
              <ClerkLoading>
                <LoadingLogo />
              </ClerkLoading>
              <ClerkLoaded>
                <Image src="/specter-logo.svg" w={8} mx="auto" mb={4} />
                <Heading size="sm" textAlign="center" mb={8}>
                  Login to Specter
                </Heading>
              </ClerkLoaded>
              <SignIn />
            </Card>
          </CardBox>
        </Box>
      </Center>
    </Flex>
  )
}

export default LoginPage
